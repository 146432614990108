import type {
    BusinessSubscriptionPlan,
    DashboardBusinessAmenityData,
    DashboardBusinessTimingData,
    DashboardBusinessRemovedImageData,
    BusinessQuota,
    DashboardBusiness,
    DashboardBusinessCategory,
    DashboardBusinessEditPermissions,
    DashboardBusinessTiming,
    DashboardBusinessAmenity,
    DashboardBusinessImagesData,
    DashboardBusinessAttachedNewImageData,
    DashboardBusinessAmenityStoreData,
    DashboardBusinessImagesStoreData,
    BusinessCouponType, BusinessCategoryTree, BusinessReportType,
} from "~/types";

interface BusinessesState {
    businesses_quota: BusinessQuota | null,
    businesses_quota_expiry: number | null,
    businesses_categories_tree: BusinessCategoryTree[] | null,
    businesses_categories_tree_expiry: number | null,
    current_dashboard_business: DashboardBusiness | null,
    current_dashboard_business_expiry: number | null,
    business_report_types: BusinessReportType[],
    business_report_types_expiry: number | null,
    business_amenities: {
        amenities: DashboardBusinessAmenity[],
        expiry: number | null
    },

    business_coupon_types: {
        coupon_types: BusinessCouponType[],
        expiry: number | null
    },


    current_dashboard_business_images: DashboardBusinessImagesStoreData,


    current_dashboard_business_timing: {
        timing: DashboardBusinessTiming | null,
        expiry: number | null,
        permission: boolean
    },
    // current_dashboard_business_timing: DashboardBusinessTiming | null,
    // current_dashboard_business_timing_expiry: number|null,
    // current_dashboard_business_timing_permission: boolean,

    current_dashboard_business_amenities: DashboardBusinessAmenityStoreData,

    business_categories: DashboardBusinessCategory[],
    business_categories_expiry: number | null,

    business_subscription_plans: BusinessSubscriptionPlan[],
    business_subscription_plans_expiry: number | null,

    /*Permissions*/
    current_dashboard_business_editing_permissions: DashboardBusinessEditPermissions | null,
    current_dashboard_business_editing_permissions_expiry: number | null,

    /*Permissions End*/

    caching_time: number;
}

export const useBusinesses = defineStore('businesses', {
    state: (): BusinessesState => {
        return {
            businesses_quota: null,
            businesses_quota_expiry: null,
            businesses_categories_tree: null,
            businesses_categories_tree_expiry: null,
            current_dashboard_business: null,
            current_dashboard_business_expiry: null,
            business_report_types: [],
            business_report_types_expiry: null,

            business_amenities: {
                amenities: [],
                expiry: null
            },

            business_coupon_types: {
                coupon_types: [],
                expiry: null
            },

            current_dashboard_business_images: {
                images: [],
                expiry: null,
                permission: false,
                total_quota: 0,
                remaining_quota: 0,
            },

            // current_dashboard_business_timing: null,
            // current_dashboard_business_timing_expiry: null,
            // current_dashboard_business_timing_permission: false,
            current_dashboard_business_timing: {
                timing: null,
                expiry: null,
                permission: false
            },

            current_dashboard_business_amenities: {
                amenities: [],
                expiry: null,
                permission: false,
            },


            business_categories: [],
            business_categories_expiry: null,

            business_subscription_plans: [],
            business_subscription_plans_expiry: null,


            /*Permissions*/
            current_dashboard_business_editing_permissions: null,
            current_dashboard_business_editing_permissions_expiry: null,
            /*Permissions End*/

            caching_time: 1000 * 10,
        }
    },
    getters: {
        /*Business Amenities*/
        businessAmenitiesData: (state) => {
            return {
                business_amenities: state.current_dashboard_business_amenities.amenities,
                permission: state.current_dashboard_business_amenities.permission,
            }
        },
        /*Business Amenities End*/

        /*Business Images*/
        businessImagesData: (state) => {
            return {
                business_images: state.current_dashboard_business_images.images,
                permission: state.current_dashboard_business_images.permission,
                remaining_quota: state.current_dashboard_business_images.remaining_quota,
                total_quota: state.current_dashboard_business_images.total_quota,
            };
        }
        /*Business Amenities End*/

    },
    actions: {
        setBusinessCategories(businessCategories: any) {
            this.business_categories = businessCategories;
            this.business_categories_expiry = Date.now();
        },
        clearBusinessCategories() {
            this.business_categories = [];
            this.business_categories_expiry = null;
        },
        isBusinessCategoriesValid() {
            if (this.business_categories_expiry != null) {
                return ((Date.now() - this.business_categories_expiry) < this.caching_time);
            }
            return true;
        },

        setBusinessReportTypes(businessReportTypes: any[]) {
            this.business_report_types = businessReportTypes;
            this.business_report_types_expiry = Date.now();
        },
        clearBusinessReportTypes() {
            this.business_report_types = [];
            this.business_report_types_expiry = null;
        },
        isBusinessReportTypesValid() {
            if (this.business_report_types_expiry != null) {
                return ((Date.now() - this.business_report_types_expiry) < this.caching_time);
            }
            return true;
        },

        setBusinessAmenities(businessAmenities: any) {
            this.business_amenities.amenities = businessAmenities;
            this.business_amenities.expiry = Date.now();
        },
        clearBusinessAmenities() {
            this.business_amenities.amenities = [];
            this.business_amenities.expiry = null;
        },
        isBusinessAmenitiesValid() {
            if (this.business_amenities.expiry != null) {
                return ((Date.now() - this.business_amenities.expiry) < this.caching_time);
            }
            return false;
        },

        setBusinessCouponTypes(businessCouponTypes: any) {
            this.business_coupon_types.coupon_types = businessCouponTypes;
            this.business_coupon_types.expiry = Date.now();
        },
        clearBusinessCouponTypes() {
            this.business_coupon_types.coupon_types = [];
            this.business_coupon_types.expiry = null;
        },
        isBusinessCouponTypesValid() {
            if (this.business_coupon_types.expiry != null) {
                return ((Date.now() - this.business_coupon_types.expiry) < this.caching_time);
            }
            return false;
        },

        setBusinessSubscriptionPlans(businessSubscriptionPlans: BusinessSubscriptionPlan[]) {
            this.business_subscription_plans = businessSubscriptionPlans;
            this.business_subscription_plans_expiry = Date.now();
        },
        clearBusinessSubscriptionPlans() {
            this.business_subscription_plans = [];
            this.business_subscription_plans_expiry = null;
        },
        isBusinessSubscriptionPlansValid() {
            if (this.business_subscription_plans_expiry != null) {
                return ((Date.now() - this.business_subscription_plans_expiry) < this.caching_time);
            }
            return true;
        },


        setBusinessesQuota(businessesQuota: any) {
            this.businesses_quota = businessesQuota;
            this.businesses_quota_expiry = Date.now();
        },
        clearBusinessesQuota() {
            this.businesses_quota = null;
            this.businesses_quota_expiry = null;
        },
        isBusinessesQuotaValid() {
            if (this.businesses_quota_expiry != null) {
                return ((Date.now() - this.businesses_quota_expiry) < this.caching_time);
            }
            return true;
        },

        setBusinessesCategoriesTree(businessesCategoriesTree: any) {
            this.businesses_categories_tree = businessesCategoriesTree;
            this.businesses_categories_tree_expiry = Date.now();
        },
        clearBusinessesCategoriesTree() {
            this.businesses_categories_tree = null;
            this.businesses_categories_tree_expiry = null;
        },
        isBusinessesCategoriesTreeValid() {
            if (this.businesses_categories_tree_expiry != null) {
                return ((Date.now() - this.businesses_categories_tree_expiry) < this.caching_time);
            }
            return true;
        },

        setCurrentDashboardBusiness(dashboardBusiness: DashboardBusiness) {
            this.current_dashboard_business = dashboardBusiness;
            this.current_dashboard_business_expiry = Date.now();
        },

        clearCurrentDashboardBusiness() {
            this.current_dashboard_business = null;
            this.current_dashboard_business_expiry = null;
        },
        isCurrentDashboardBusinessValid() {
            if (this.current_dashboard_business_expiry != null) {
                return ((Date.now() - this.current_dashboard_business_expiry) < this.caching_time);
            }
            return true;
        },
        updateCurrentDashboardBusinessLogo(logoUrl: string) {
            if (this.current_dashboard_business != null) {
                this.current_dashboard_business.logo_card = logoUrl;
                this.current_dashboard_business.logo_thumb = logoUrl;
            }
        },

        updateCurrentDashboardBusinessCover(coverUrl: string) {
            if (this.current_dashboard_business != null) {
                this.current_dashboard_business.cover = coverUrl;
            }
        },

        updateCurrentDashboardBusinessCategories(businessCategories: DashboardBusinessCategory[]) {
            if (this.current_dashboard_business != null) {
                this.current_dashboard_business.business_categories =
                    businessCategories;
            }
        },

        /*Business Timing*/
        setCurrentDashboardBusinessTimingData(dashboardBusinessTimingData: DashboardBusinessTimingData) {
            this.current_dashboard_business_timing.timing = dashboardBusinessTimingData.business_timing;
            this.current_dashboard_business_timing.permission = dashboardBusinessTimingData.permission;
            this.current_dashboard_business_timing.expiry = Date.now();
        },

        clearCurrentDashboardBusinessTiming() {
            this.current_dashboard_business_timing.timing = null;
            this.current_dashboard_business_timing.permission = false;
            this.current_dashboard_business_timing.expiry = null;
        },

        isCurrentDashboardBusinessTimingValid() {
            if (this.current_dashboard_business_timing?.expiry) {
                return ((Date.now() - this.current_dashboard_business_timing?.expiry) < this.caching_time);
            }
            return false;
        },
        /*Business Timing Ends*/
        /*Business Amenities*/
        setCurrentDashboardBusinessAmenitiesData(dashboardBusinessAmenitiesData: DashboardBusinessAmenityData) {
            this.current_dashboard_business_amenities.amenities = dashboardBusinessAmenitiesData.business_amenities;
            this.current_dashboard_business_amenities.permission = dashboardBusinessAmenitiesData.permission;
            this.current_dashboard_business_amenities.expiry = Date.now();
        },

        addAmenityToCurrentDashboardBusiness(businessAmenity: DashboardBusinessAmenity) {
            this.current_dashboard_business_amenities.amenities?.push(businessAmenity);
            this.current_dashboard_business_amenities.expiry = Date.now();
        },

        removeAmenityFromCurrentDashboardBusiness(businessAmenity: DashboardBusinessAmenity) {
            this.current_dashboard_business_amenities.amenities = this.current_dashboard_business_amenities.amenities?.filter(amenity => {
                return amenity.id != businessAmenity.id;
            }) as DashboardBusinessAmenity[];
            this.current_dashboard_business_amenities.expiry = Date.now();
        },
        clearCurrentDashboardBusinessAmenitiesData() {
            this.current_dashboard_business_amenities.amenities = [];
            this.current_dashboard_business_amenities.permission = false;
            this.current_dashboard_business_amenities.expiry = null;
        },
        isCurrentDashboardBusinessAmenitiesDataValid() {
            if (this.current_dashboard_business_amenities.expiry != null) {
                return ((Date.now() - this.current_dashboard_business_amenities.expiry) < this.caching_time);
            }
            return false;
        },
        /*Business Amenities Ends*/


        /*Business Images*/

        setCurrentDashboardBusinessImagesData(imagesData: DashboardBusinessImagesData) {
            this.current_dashboard_business_images.images = imagesData.business_images;
            this.current_dashboard_business_images.permission = imagesData.permission;
            this.current_dashboard_business_images.total_quota = imagesData.total_quota;
            this.current_dashboard_business_images.remaining_quota = imagesData.remaining_quota;
            this.current_dashboard_business_images.expiry = Date.now();
        },
        clearCurrentDashboardBusinessImages() {
            this.current_dashboard_business_images.images = [];
            this.current_dashboard_business_images.permission = false;
            this.current_dashboard_business_images.total_quota = 0;
            this.current_dashboard_business_images.remaining_quota = 0;
            this.current_dashboard_business_images.expiry = null;
        },
        isCurrentDashboardBusinessImagesValid() {
            if (this.current_dashboard_business_images.expiry != null) {
                return ((Date.now() - this.current_dashboard_business_images.expiry) < this.caching_time);
            }
            return false;
        },

        updateCurrentDashboardBusinessImagesDataAfterAttachment(newImagesData: DashboardBusinessAttachedNewImageData) {
            this.current_dashboard_business_images.images.push(newImagesData.image);
            this.current_dashboard_business_images.permission = newImagesData.permission;
            this.current_dashboard_business_images.total_quota = newImagesData.total_quota;
            this.current_dashboard_business_images.remaining_quota = newImagesData.remaining_quota;
            this.current_dashboard_business_images.expiry = Date.now();
        },

        updateCurrentDashboardBusinessImagesDataAfterRemovalAtIndex(index: number, newImagesData: DashboardBusinessRemovedImageData) {
            this.current_dashboard_business_images.images.splice(index, 1);
            this.current_dashboard_business_images.permission = newImagesData.permission;
            this.current_dashboard_business_images.total_quota = newImagesData.total_quota;
            this.current_dashboard_business_images.remaining_quota = newImagesData.remaining_quota;
            this.current_dashboard_business_images.expiry = Date.now();
        },

        /*Business Images End*/

        /*Business Custom Product*/
        updateCurrentDashboardBusinessUnCategorizedCustomProductsCount(unCategorizedCustomProductsCount: number) {
            if (this.current_dashboard_business) {
                this.current_dashboard_business.un_categorized_custom_products = unCategorizedCustomProductsCount;
            }
        },
        /*Business Custom Product End*/

        /*Business Custom Service*/
        updateCurrentDashboardBusinessUnCategorizedCustomServicesCount(unCategorizedCustomServicesCount: number) {
            if (this.current_dashboard_business) {
                this.current_dashboard_business.un_categorized_custom_services = unCategorizedCustomServicesCount;
            }
        },
        /*Business Custom Product End*/

        /*Permissions*/

        setCurrentDashboardBusinessEditingPermissions(dashboardBusinessPermissions: DashboardBusinessEditPermissions) {
            this.current_dashboard_business_editing_permissions = dashboardBusinessPermissions;
            this.current_dashboard_business_editing_permissions_expiry = Date.now();
        },
        clearCurrentDashboardBusinessEditingPermissions() {
            this.current_dashboard_business_editing_permissions = null;
            this.current_dashboard_business_editing_permissions_expiry = null;
        },
        isCurrentDashboardBusinessEditingPermissionsValid() {
            if (this.current_dashboard_business_editing_permissions_expiry != null) {
                return ((Date.now() - this.current_dashboard_business_editing_permissions_expiry) < this.caching_time);
            }
            return false;
        },
        /*Permissions End*/
    }
});